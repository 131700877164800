<template>
  <div class="breadcrumbs-wrapper">
    <router-link to="/for-sale"
      ><i class="material-icons">arrow_back_ios</i> търсене на имот
    </router-link>
    <span> / {{ estateTypeName }}, {{ region }}, {{ subRegion }}</span>
  </div>
</template>

<script>
export default {
  props: ["estateTypeName", "region", "subRegion"],
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";


</style>
