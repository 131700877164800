<template>
<div>
    <h1>Имоти под наем</h1>
    <div>
        <rent-listing></rent-listing>
    </div>
</div>
</template>

<script>
import RentListing from '../layouts/RentListing.vue' 
export default {
    components: {
        RentListing,
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";

h1 {
  margin: 50px 10px 30px 10px;
  padding-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  text-transform: uppercase;
  color: $color-text;
}
</style>