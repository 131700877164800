<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-wrapper__content-wrapper">
        <p style="color: #e8d845"> www.imotino.eu - Лесен начин за намиране на имот. {{ date.getFullYear() }} г.</p>
      </div>
    </div>
  </footer>
</template>

<script setup>
  const date = new Date();
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
