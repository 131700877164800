<template>
  <ul class="desktop-navigation">
    <router-link to="/"><li><i class="material-icons">home</i> Начало</li></router-link>
    <!-- <router-link to="/new-buildings"><li>Ново строителство</li></router-link> -->
    <router-link to="/for-sale"><li><i class="material-icons">real_estate_agent</i> Продажби</li></router-link>
    <router-link to="/for-rent"><li><i class="material-icons">supervised_user_circle</i>Наеми</li></router-link>
    <router-link to="/about-us"><li><i class="material-icons">quiz</i> За сайта</li></router-link>
  </ul>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
a.router-link-active {
  color: $color-gold;
  i {
    color: #fff;
    border: 1px solid #fff;
  }
}
</style>
