<!-- Agency or agency card component without Avatar -->
<template>
    <div class="agent-contact-card-wrapper">
      <div class="agent-info">
        <div class="agent-contacts">
          <p>Информация за агенцията:</p>
          <span><i class="material-icons">real_estate_agent</i> {{ agencyName }}</span>
          <span><i class="material-icons">mail</i> {{ companyEmail }}</span>
          <span><i class="material-icons">phone_android</i> {{ companyPhone }}</span>
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    props: [
      'companyEmail',
      'companyPhone',
      'agencyName',
    ],
  }
</script>
  
<style lang="scss" scoped>
  @import "../../../assets/scss/main.scss";
</style>
  