<template>
<div>
    <h1>Имоти за продажба</h1>
    <div>
        <sales-listing></sales-listing>
    </div>
</div>
</template>

<script>
import SalesListing from '../layouts/SalesListing.vue'

export default {
    components: {
        SalesListing,
        
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";

h1 {
  margin: 50px 10px 30px 10px;
  padding-bottom: 10px;
  font-size: 26px;
  font-weight: 300;
  text-transform: uppercase;
  color: $color-text;
}
</style>