<template>
  <div v-if="noPicture" class="property-gallery-wrapper">
    <div class="primary-picture">
      <img :src="pictures[0]" alt="primary picture" @click="showImg(0)" />
    </div>
    <div class="pictures-grid">
      <div
        class="pictures-grid__picture-wrapper"
        v-for="(picture, index) in pictures.slice(1, 5)"
        :key="index"
        @click="showImg(index + 1)"
      >
        <img :src="picture" :alt="index" />
      </div>
      <div class="picture-stats">
        <span class="pictures-count-tag" @click="showImg(0)"> разгледай всички {{ pictures.length }} снимки на този имот</span>
      </div>
    </div>

    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="lightboxImgs"
      :index="lightboxIndex"
      @hide="onLightboxHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },
  props: ["pictures"],
  data() {
    return {
      lightboxVisible: false,
      lightboxImgs: [],
      lightboxIndex: 0,
      noPicture: this.pictures.length > 0
    };
  },
  methods: {
    showImg(index) {
      this.lightboxImgs = this.pictures;
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    onLightboxHide() {
      this.lightboxVisible = false;
    },
  },
  computed: {
    hasNoPicture() {
      if (!this.pictures[0]) {
        return this.noPicture
      } else {
        return this.pictures[0]
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
