<!-- Agent or agency card component without Avatar -->
<template>
  <div class="agent-contact-card-wrapper">
    <div class="agent-info">
      <div class="agent-contacts" v-for="broker in brokers" :key="broker.id">
        <p>Информация за брокера: </p>
        <span
          ><i class="material-icons">real_estate_agent</i>
          {{ broker.name }}</span
        >
        <span><i class="material-icons">mail</i> {{ broker.email }}</span>
        <span
          ><i class="material-icons">phone_android</i> {{ broker.phone }}</span
        >
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
export default {
  props: ["brokers"],
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
